import {
  Flex,
  Box,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
import Image from 'next/image';
import {IconX, IconMenu2} from '@tabler/icons';
import {LoggedInMobileActions, LoggedOutMobileActions} from './nav-bar';

interface MobileNavProps {
  username?: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const NavBarMobileMenu: React.FC<MobileNavProps> = ({
  username,
  isOpen,
  onClose,
  onOpen,
}: MobileNavProps) => {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent bgColor="white">
        <DrawerHeader>
          <Flex justifyContent="space-between">
            <Box width="24px">
              <IconX size="100%" color="black" stroke="2" onClick={onClose} />
            </Box>
            <Image
              height="39px"
              width="132px"
              src="/assets/logo-dark.svg"
              alt="Showpiece Logo"
            />
            <Box width="24px" />
          </Flex>
        </DrawerHeader>
        <Flex
          flexDir="column"
          justifyContent="space-between"
          px={6}
          alignItems="center"
        >
          <IconMenu2 size="24px" color="white" stroke="2" onClick={onOpen} />
          {username ? (
            <LoggedInMobileActions name={username} />
          ) : (
            <LoggedOutMobileActions />
          )}
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};
