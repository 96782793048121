import {Box, keyframes} from '@chakra-ui/react';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {CONTENTS} from '../components/1c-magenta-page/story';
import {
  SectionId as MagentaSectionId,
  SECTIONS as MagentaSection,
} from '../shared/sections/1-c-magenta';
import {
  SectionId as EdwardSectionId,
  SECTIONS as EdwardSection,
} from '../shared/sections/edward8-penny';
import {
  SectionId as ReigningQueen,
  SECTIONS as ReigningQueenSection,
} from '../shared/sections/reigning-queen';

export interface IScollContextProps {
  FAKE_LOADING_TIMEOUT: number;
  scrollRef?: any;
  entryAnimationDelay?: any;
  setFakeLoadingTimeout?: any;
  inAndDownAnimation?: any;
  semiHighlighSvgIds?: any;
  highlighSvgIds?: any;
  positions?: any;
  storySectionRefs?: any;
  mapVis?: boolean;
  isNavOverLightBackground: boolean;
  navRef?: any;
  scrollY?: number;
  activeSection: MagentaSectionId | EdwardSectionId | ReigningQueen;
}

const inAndDownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FAKE_LOADING_TIMEOUT = 1500;
const ScrollContext = React.createContext<IScollContextProps>({
  FAKE_LOADING_TIMEOUT: 1500,
  isNavOverLightBackground: false,
  activeSection: 'stamp',
});

interface IScollProviderProps {
  children?: React.ReactNode;
}

interface IScrollProviderPropsExtend extends IScollProviderProps {
  sections:
    | typeof MagentaSection
    | typeof EdwardSection
    | typeof ReigningQueenSection;
  defaultSection: MagentaSectionId | EdwardSectionId | ReigningQueen;
}

const ScrollProvider: React.FC<IScrollProviderPropsExtend> = ({
  sections,
  children,
  defaultSection,
}) => {
  const scrollRef = useRef<HTMLDivElement>();
  const navRef = useRef<HTMLDivElement>();
  const [scrollY, setScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState<
    MagentaSectionId | EdwardSectionId | ReigningQueen
  >(defaultSection);
  const [closetSectionKey, setClosetSectionKey] = useState<string | null>(null);
  const storySectionRefs = useRef<Record<string, HTMLDivElement>>({});
  const [shouldHoldFakeInitialTimeoutOnEntry, setFakeLoadingTimeout] =
    useState(true);

  useEffect(() => {
    const ref = scrollRef.current;
    const setNewValue = () => ref && setScrollY(ref.scrollTop);
    if (ref) {
      ref.addEventListener('scroll', setNewValue);
    }
    setNewValue();
    return () => ref && ref.removeEventListener('scroll', setNewValue);
  }, [scrollRef]);

  const [windowDimensions, setWindowDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const setNewValue = () => {
      setWindowDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', setNewValue);
    setNewValue();
    return () => window.removeEventListener('resize', setNewValue);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const observers = (
      Object.keys(sections) as MagentaSectionId[] | EdwardSectionId[]
    ).map((id: MagentaSectionId | EdwardSectionId) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActiveSection(id);
          }
        },
        {rootMargin: '-60px', threshold: 0.1},
      );
      observer.observe(document.getElementById(id) as HTMLElement);
      return observer;
    });
    return () => observers.forEach((observer) => observer.disconnect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRef]);

  const positions = useMemo(() => {
    const contentPositions: Record<string, number> = {};
    CONTENTS.forEach((content) => {
      const boxRef = storySectionRefs.current[content.key];
      const relativeY = boxRef ? boxRef?.getBoundingClientRect().top : 0;

      const percentageFromTop = Math.max(
        0,
        Math.min(1, relativeY / windowDimensions.height),
      );
      const percentageFromHalfway = boxRef
        ? 1 - Math.abs(0.5 - percentageFromTop) / 0.5
        : 0.5;

      contentPositions[content.key] = percentageFromHalfway;
    });

    return contentPositions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowDimensions.height, storySectionRefs.current, scrollRef, scrollY]);

  useEffect(() => {
    const [[closestKey, closestKeyValue], ...rest] = Object.entries(
      positions,
    ).sort(([, a], [, b]) => b - a);
    const [, furtherestKeyValue] = rest.pop() as [string, number];
    if (furtherestKeyValue === 0 && closestKeyValue === 0) {
      setClosetSectionKey(null);
    } else {
      setClosetSectionKey(closestKey);
    }
  }, [positions]);

  const [highlighSvgIds, semiHighlighSvgIds] = useMemo(() => {
    if (closetSectionKey) {
      const found = CONTENTS.find(
        (content) => content.key === closetSectionKey,
      );
      if (found) {
        return [found?.highlighSvgIds, found?.semiHighlighSvgIds];
      }
    }
    return [[], []];
  }, [closetSectionKey]);

  const entryAnimationDelay = shouldHoldFakeInitialTimeoutOnEntry
    ? FAKE_LOADING_TIMEOUT + 500
    : 0;
  return (
    <ScrollContext.Provider
      value={{
        FAKE_LOADING_TIMEOUT,
        scrollRef,
        entryAnimationDelay,
        setFakeLoadingTimeout,
        inAndDownAnimation,
        semiHighlighSvgIds,
        highlighSvgIds,
        positions,
        storySectionRefs,
        activeSection,
        isNavOverLightBackground: !!sections[activeSection]?.isLightBackground,
        navRef,
        scrollY,
      }}
    >
      <Box
        h="100vh"
        ref={scrollRef as any}
        sx={{
          scrollBehavior: 'smooth',
          scrollSnapType: ['none', 'none', 'none', 'y mandatory'],
          overflowY: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </Box>
    </ScrollContext.Provider>
  );
};

const useScrollContext: () => IScollContextProps = () => {
  return useContext(ScrollContext);
};

export {ScrollProvider, useScrollContext};
