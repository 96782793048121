import {Box, Container, Stack} from '@chakra-ui/layout';
import {useBreakpointValue} from '@chakra-ui/react';
import React, {useLayoutEffect, useRef} from 'react';
import {useScrollContext} from '../../hooks/scrollable-context';
import {SectionId} from '../../shared/sections/1-c-magenta';
import {AnimatedMap} from './animated-map';
import {IContent, StoryContentItem} from './story-content-item';

export const CONTENTS: IContent[] = [
  {
    key: 'one',
    highlighSvgIds: ['uk'],
    semiHighlighSvgIds: [],
    text: {
      header: 'The story begins...',
      article:
        'From 1831 until its independence in 1966, Guyana was a colony of the British Empire known as British Guiana. Due to the control of Great Britain, the colony’s postal service was run from London, with ships delivering mail between Falmouth and Georgetown. Reliability was often problematic, with packages often being lost or arriving late. In 1850, the first adhesive postage stamps of British Guiana were issued to create a more frequent, modernised postal service. However, since the designs were approved and ordered from Waterloo & Sons of London, they would fail to arrive in time for the launch.',
    },
  },
  {
    key: 'two',
    highlighSvgIds: ['uk', 'uk-to-bg', 'bg', 'bg-text'],
    semiHighlighSvgIds: [],
    text: {
      date: '1856',
      header: 'The Local Postmaster',
      sub: 'British Guiana (now Guyana)',
      article:
        'Time would become an issue again in 1856 when only 10% of the expected stamps for the region arrived from Great Britain. The Postmaster was forced to improvise a limited run of provisional stamps made and signed off at the local newspaper, the Royal Gazette.',
    },
  },
  {
    key: 'three',
    highlighSvgIds: ['bg', 'bg-text'],
    semiHighlighSvgIds: ['uk'],
    text: {
      header: 'Mission accomplished',
      article:
        'The small print run worked as a stop gap and the stamps were withdrawn 8 weeks later. One-cent stamps only covered local newspaper postage rates and as a result most were destroyed shortly after use.',
    },
  },
  {
    key: 'four',
    highlighSvgIds: ['bg', 'bg-text'],
    semiHighlighSvgIds: ['uk'],
    text: {
      date: '1873',
      header: 'The one that got away',
      article:
        "The 1c Magenta would have been forgotten if it were not for Vernon Vaughan, a 12-year-old Scottish boy, who found one odd stamp among his uncle's papers in 1873.",
    },
  },
  {
    key: 'five',
    highlighSvgIds: ['uk', 'uk-text', 'uk-to-bg'],
    semiHighlighSvgIds: ['bg', 'bg-text'],
    text: {
      header: 'The journey begins...',
      article:
        'The budding philatelist soon sold it for six shillings to Neil Ross McKinnon, who sent his collection to the UK for sale. ',
    },
  },
  {
    key: 'six',
    highlighSvgIds: ['uk-text', 'us', 'fr', 'fr-to-us', 'uk-to-fr'],
    semiHighlighSvgIds: ['bg', 'bg-text', 'uk'],
    text: {
      article:
        'And so began the decades-long, cross-continental journey of the 1c Magenta.',
    },
  },
];

export function Story(): JSX.Element {
  const {semiHighlighSvgIds, highlighSvgIds, positions, storySectionRefs} =
    useScrollContext();
  const [stackHeight, setStackHeight] = React.useState(0);

  const stackLayoutRef = useRef<HTMLDivElement>();
  const mapRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (stackLayoutRef.current) {
        setStackHeight(stackLayoutRef.current.offsetHeight);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [stackLayoutRef]);

  const svgHighlights =
    useBreakpointValue({
      base: ['uk', 'uk-to-bg', 'bg', 'bg-text'],
      sm: highlighSvgIds,
    }) || highlighSvgIds;
  const svgSemiHighlights =
    useBreakpointValue({
      base: [],
      sm: semiHighlighSvgIds,
    }) || semiHighlighSvgIds;

  return (
    <Box
      position="relative"
      background={[`url('/assets/paper.png') no-repeat center`, 'none']}
      backgroundSize={['cover', 'none']}
      pb={['20', '0']}
      height={['auto', `calc(100vh + ${stackHeight}px)`]}
    >
      <Box
        ref={mapRef as any}
        overflow="hidden"
        top="0"
        position={['relative', 'sticky']}
        height={['90vh', '100vh']}
        background={['none', `url('/assets/paper.png') no-repeat center`]}
        backgroundSize={['none', 'cover']}
      >
        <Box
          display={['block', 'none']}
          px="10"
          pt="20"
          mb="8px"
          fontFamily="spectral"
          fontSize="18px"
          fontWeight="400"
          color="midnight"
        >
          From humble beginnings...
        </Box>
        <Box
          display="flex"
          position={['relative', 'absolute']}
          height={['auto', '100vh']}
          width={['280vw', '170vw']}
          right={['-35px', '-80vw']}
          left={['-60vw', 'unset']}
          top={['8', '10vh']}
          alignItems="center"
        >
          <AnimatedMap
            semiHighlighSvgIds={svgSemiHighlights}
            highlighSvgIds={svgHighlights}
          />
        </Box>
      </Box>
      <Box
        position={['relative', 'absolute']}
        top={[0, '50vh']}
        bottom={0}
        left={0}
        right={0}
      >
        <Container position="relative" maxW="container.xl">
          <Stack
            ref={stackLayoutRef as any}
            position={['relative', 'absolute']}
            spacing={['56px', '180px']}
            top="0px"
            left="0"
            pl="10"
            pr={['10', 0]}
            w={['auto', '50vw']}
          >
            {CONTENTS.map((content) => {
              return (
                <StoryContentItem
                  id={'story' as SectionId}
                  ref={(ref) => {
                    storySectionRefs.current[content.key] = ref;
                  }}
                  maxW="376px"
                  css={{scrollSnapStop: 'always', scrollSnapAlign: 'center'}}
                  opacity={[1, Math.min(1, positions[content.key])]}
                  content={content}
                  key={content.key}
                />
              );
            })}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
