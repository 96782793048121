import {Box} from '@chakra-ui/react';
import React from 'react';

export interface IContent {
  key: string;
  highlighSvgIds: string[];
  semiHighlighSvgIds: string[];
  text: {
    date?: string;
    header?: string;
    sub?: string;
    article?: string;
  };
}

export const StoryContentItem: React.FC<
  {content: IContent} & React.ComponentPropsWithRef<typeof Box>
> = React.forwardRef(({content, ...boxProps}, ref) => {
  return (
    <Box ref={ref} {...boxProps}>
      {content.text.date && (
        <Box mb="8px" fontFamily="oldStandard" fontSize="24px" fontWeight="400">
          {content.text.date}
        </Box>
      )}
      {content.text.header && (
        <Box mb="8px" fontFamily="magenta" fontSize="28px" fontWeight="600">
          {content.text.header}
        </Box>
      )}
      {content.text.sub && (
        <Box
          mb="8px"
          fontFamily="spectral"
          fontSize="18px"
          fontStyle="italic"
          color="stampRed"
          fontWeight="500"
        >
          {content.text.sub}
        </Box>
      )}
      {content.text.article && (
        <Box
          mb="8px"
          textAlign="justify"
          fontFamily="spectral"
          fontSize="18px"
          fontWeight="400"
          color="midnight"
        >
          {content.text.article}
        </Box>
      )}
    </Box>
  );
});
