import {ApolloError} from '@apollo/client';
import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';
import {NavVariant, NavVariantProps} from './nav-bar';
import {theme} from '../../style/theme';
import {useCognito} from '../../hooks/cognito';
import {useAppToast} from '../../hooks/app-toast';
import {BLOG_URL} from '../../shared/consts';

const UserNavigatorLink: React.FC<{
  title: string;
  path: string;
  variant: NavVariant;
}> = (props) => {
  return (
    <MenuItem _hover={{bg: 'none'}} _focus={{bg: 'none'}} bg="none">
      <Link href={props.path}>
        <a
          style={{
            width: '100%',
            textAlign: 'center',
            color: NavVariantProps[props.variant].color,
            fontfeaturesettings: 'unset',
            ...theme.textStyles.h7,
          }}
        >
          {props.title}
        </a>
      </Link>
    </MenuItem>
  );
};

export const UserNavigator: React.FC<{
  username: string;
  variant: NavVariant;
}> = (props) => {
  const cognito = useCognito();
  const toast = useAppToast();

  const handleLogout = () => {
    cognito.logout().catch((e) => {
      if (!(e instanceof ApolloError)) {
        toast.showErrorToast(e);
      }
    });
  };
  return (
    <>
      <Menu>
        <MenuButton>
          <Flex
            justifyContent="center"
            alignItems="center"
            userSelect="none"
            cursor="pointer"
          >
            <Image
              src="/assets/account_circle.svg"
              width="32px"
              height="32px"
              alt="profile icon"
            />
            <Text
              pl="12px"
              pr="6px"
              fontFamily={theme.fonts.body}
              style={theme.textStyles.h7}
            >
              {props.username}
            </Text>
            <Image
              width="12px"
              height="6px"
              src="/assets/user-menu-arrow-down.svg"
              alt="downwards arrow"
            />
          </Flex>
        </MenuButton>
        <MenuList
          mt="17px"
          border="none"
          boxShadow={theme.shadows.card}
          ml={[0, 0, 0, 0, -12]}
          bgColor={NavVariantProps[props.variant].bg}
          pb={5}
          pt={5}
          borderTopLeftRadius="none"
          borderTopRightRadius="none"
        >
          {/* TODO: REFER */}
          {/* <UserNavigatorLink
            title="Refer a Friend"
            path={Routes.REFER_A_FRIEND}
            variant={props.variant}
          /> */}
          <UserNavigatorLink
            title="My Account"
            path="/my-account"
            variant={props.variant}
          />
          <Text
            cursor="pointer"
            style={{
              width: '100%',
              textAlign: 'center',
              color: NavVariantProps[props.variant].color,
              fontfeaturesettings: 'unset',
              ...theme.textStyles.h7,
            }}
            px="12px"
            py="6px"
            onClick={handleLogout}
          >
            Log Out
          </Text>
        </MenuList>
      </Menu>
    </>
  );
};

export const LearnMoreNavigator: React.FC<{
  variant: NavVariant;
}> = (props) => {
  return (
    <>
      <Menu>
        <MenuButton>
          <Flex
            justifyContent="center"
            alignItems="center"
            userSelect="none"
            cursor="pointer"
          >
            <Text
              pl="12px"
              pr="6px"
              fontFamily={theme.fonts.body}
              style={theme.textStyles.h7}
            >
              Learn
            </Text>
            <Image
              width="12px"
              height="6px"
              src="/assets/user-menu-arrow-down.svg"
              alt="downwards arrow"
            />
          </Flex>
        </MenuButton>
        <MenuList
          mt="22px"
          border="none"
          boxShadow={theme.shadows.card}
          bgColor={NavVariantProps[props.variant].bg}
          pb={5}
          pt={5}
          borderTopLeftRadius="none"
          borderTopRightRadius="none"
        >
          <UserNavigatorLink
            title="Blog"
            path={BLOG_URL}
            variant={props.variant}
          />
          <UserNavigatorLink
            title="How It Works"
            path="/how-it-works"
            variant={props.variant}
          />
          <UserNavigatorLink
            title="About Us"
            path="/about-us"
            variant={props.variant}
          />
        </MenuList>
      </Menu>
    </>
  );
};
