import {Box, Container, Flex, HStack} from '@chakra-ui/layout';
import Image from 'next/image';
import Link from 'next/link';
import {IconLock, IconMenu2, IconUser} from '@tabler/icons';
import {useDisclosure} from '@chakra-ui/hooks';
import {Text} from '@chakra-ui/react';
import {Button} from '@chakra-ui/button';
import {useRouter} from 'next/router';
import {ApolloError} from '@apollo/client';
import {theme} from '../../style/theme';
import {useScrollContext} from '../../hooks/scrollable-context';
import {useCognito} from '../../hooks/cognito';
import {useAppToast} from '../../hooks/app-toast';
import {NavBarMobileMenu} from './nav-bar-mobile-menu';
import {LearnMoreNavigator, UserNavigator} from './user-nav-bar';
import {BLOG_URL, Routes} from '../../shared/consts';

export type NavVariant = 'dark' | 'light';

export const NavVariantProps: {
  [key in NavVariant]: {
    bg: string;
    logo: string;
    color: string;
    borderBottom: string;
  };
} = {
  dark: {
    bg: 'midnight',
    logo: '/assets/logo-light.svg',
    color: 'white',
    borderBottom: 'inherit',
  },
  light: {
    bg: '#f5f7f7f5',
    logo: '/assets/logo-dark.svg',
    color: theme.colors.charcoal,
    borderBottom: '1px solid rgba(113,128,150, 0.2)',
  },
};

export interface NavProps {
  variant?: keyof typeof NavVariantProps;
  isCheckout?: boolean;
  username?: string;
  onClick?: () => void;
  hideNavBarActions?: boolean;
  position?: 'down' | 'up';
}

export const NAV_HEIGHT_SM = '60px';
const NAV_HEIGHT_LG = '80px';

const ACTIONS_SPACING = 10; // 4 * 10 = 40px

const LoggedOutActions: React.FC<{
  onClick: (() => void) | undefined;
  variant: NavVariant;
}> = (props) => {
  const router = useRouter();
  const mainPage = router.pathname.split('/')[1];
  const activePage = router.pathname.split('/')[2];

  return (
    <Flex flex={1} justifyContent="flex-end" alignItems="center">
      <Link href="/marketplace">
        <Text
          ml={ACTIONS_SPACING}
          fontWeight="600"
          color={props.variant === 'light' ? 'charcoal' : 'white'}
          cursor="pointer"
        >
          <Box as="span" display="inline-block" position="relative" px="15px">
            Marketplace
            <Text
              as="span"
              position="absolute"
              top="-10px"
              right={0}
              fontSize="10px"
              color="aqua.500"
            >
              BETA
            </Text>
          </Box>
        </Text>
      </Link>
      <Link href="/how-it-works">
        <Text
          ml={ACTIONS_SPACING}
          fontWeight="600"
          color={props.variant === 'light' ? 'charcoal' : 'white'}
          cursor="pointer"
        >
          How it Works
        </Text>
      </Link>
      <Link href="/about-us">
        <Text
          ml={ACTIONS_SPACING}
          fontWeight="600"
          color={props.variant === 'light' ? 'charcoal' : 'white'}
          cursor="pointer"
        >
          About Us
        </Text>
      </Link>
      <Link href={Routes.GIFT_CARDS}>
        <Text
          ml={ACTIONS_SPACING}
          fontWeight="600"
          color={props.variant === 'light' ? 'charcoal' : 'white'}
          cursor="pointer"
        >
          Gift Cards
        </Text>
      </Link>
      <Link href={BLOG_URL}>
        <a target="_blank">
          <Flex
            color={props.variant === 'light' ? 'charcoal' : 'white'}
            ml={ACTIONS_SPACING}
          >
            Blog
          </Flex>
        </a>
      </Link>
      <Text
        as="button"
        fontWeight="600"
        color={props.variant === 'light' ? 'light' : 'charcoal'}
        bg={props.variant === 'dark' ? 'light' : 'white'}
        ml={ACTIONS_SPACING}
        onClick={() => {
          router.push(
            `/login${
              mainPage === 'checkout' ? `?returnTo=checkout/${activePage}` : ''
            }`,
          );
        }}
        w="80px"
        height="36px"
        borderRadius="12px"
      >
        Log In
      </Text>
      <Text
        as="button"
        fontWeight="600"
        color={props.variant === 'light' ? 'white' : 'charcoal'}
        bg={props.variant === 'light' ? 'charcoal' : 'white'}
        ml="8px"
        onClick={() => {
          router.push('/create-account');
        }}
        w="90px"
        height="36px"
        borderRadius="12px"
      >
        Sign Up
      </Text>
    </Flex>
  );
};

const LoggedInActions: React.FC<{username: string; variant: NavVariant}> = (
  props,
) => {
  return (
    <HStack
      flex={1}
      justifyContent="flex-end"
      spacing={{base: 5, lg: 10}}
      pr={2}
    >
      <Link href="/marketplace">
        <a>
          <Text color={NavVariantProps[props.variant].color}>
            <Box as="span" display="inline-block" position="relative" px="15px">
              Marketplace
              <Text
                as="span"
                position="absolute"
                top="-10px"
                right={0}
                fontSize="10px"
                color="aqua.500"
              >
                BETA
              </Text>
            </Box>
          </Text>
        </a>
      </Link>

      <Link href="/my-collection">
        <a>
          <Flex color={NavVariantProps[props.variant].color}>
            My Collection
          </Flex>
        </a>
      </Link>
      <LearnMoreNavigator variant={props.variant} />
      <Link href={Routes.GIFT_CARDS}>
        <a>
          <Flex color={NavVariantProps[props.variant].color}>Gift Cards</Flex>
        </a>
      </Link>
      <UserNavigator username={props.username} variant={props.variant} />
    </HStack>
  );
};

const CheckoutActions: React.FC = () => {
  return (
    <Flex flex={1} justifyContent="flex-end" alignItems="center">
      <IconLock size="24px" color={theme.colors.aqua[400]} stroke="2" />
      <Flex ml={3} color="white">
        Secure Checkout
      </Flex>
    </Flex>
  );
};

export const LoggedInMobileActions: React.FC<{name: string}> = ({name}) => {
  const router = useRouter();
  const cognito = useCognito();
  const toast = useAppToast();

  const handleLogout = () => {
    cognito.logout().catch((e) => {
      if (!(e instanceof ApolloError)) {
        toast.showErrorToast(e);
      }
    });
  };
  return (
    <Flex flexWrap="wrap" justifyContent="space-between" flexDirection="row">
      <Flex flexWrap="wrap" w="100%" flexDirection="row" mb="60px">
        <Text
          textStyle="button"
          color="tone3"
          mt="50px"
          w="100%"
          textAlign="center"
        >
          Welcome back{' '}
          <Text as="span" color="aqua.500">
            {name}
          </Text>
        </Text>

        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push('/marketplace')}
          my="24px"
          position="relative"
        >
          <Box as="span" display="inline-block" position="relative" px="12px">
            Marketplace
            <Text
              as="span"
              position="absolute"
              top="-5px"
              right={0}
              textStyle="showpiece_h8"
              color="aqua.500"
            >
              BETA
            </Text>
          </Box>
        </Text>

        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push('/my-collection')}
          mb="24px"
        >
          My Collection
        </Text>
        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push('/my-account')}
          mb="24px"
        >
          My Account
        </Text>
      </Flex>
      <Flex
        flexWrap="wrap"
        w="100%"
        flexDirection="row"
        justifyContent="center"
      >
        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push('/')}
          mb="24px"
        >
          Showpiece home
        </Text>
        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push('/how-it-works')}
          mb="24px"
        >
          How it Works
        </Text>
        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push('/about-us')}
          mb="24px"
        >
          About Us
        </Text>
        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => window.open(BLOG_URL, '_blank')}
          mb="24px"
        >
          Blog
        </Text>
        <Text
          w="100%"
          color="charcoal"
          as="button"
          textStyle="h6"
          onClick={() => router.push(Routes.GIFT_CARDS)}
          mb="24px"
        >
          Gift Cards
        </Text>
        <Button
          bgColor="charcoal"
          colorScheme="gray"
          color="white"
          onClick={handleLogout}
          size="sm"
          w="88px"
          height="36px"
          borderRadius="12px"
        >
          Log Out
        </Button>
      </Flex>
    </Flex>
  );
};

export const LoggedOutMobileActions: React.FC = () => {
  const router = useRouter();
  return (
    <>
      <Button
        bgColor="charcoal"
        colorScheme="gray"
        color="white"
        onClick={() => router.push('/login')}
        size="sm"
        w="137px"
        mt="60px"
        mb={8}
        height="36px"
        borderRadius="12px"
      >
        Log In
      </Button>
      <Text
        color="charcoal"
        as="button"
        textStyle="h6"
        onClick={() => router.push('/')}
        mb={8}
      >
        Showpiece Home
      </Text>
      <Text
        w="100%"
        color="charcoal"
        as="button"
        textStyle="h6"
        onClick={() => router.push('/marketplace')}
        mb={8}
      >
        <Box as="span" display="inline-block" position="relative" px="12px">
          Marketplace
          <Text
            as="span"
            position="absolute"
            top="-5px"
            right={0}
            textStyle="showpiece_h8"
            color="aqua.500"
          >
            BETA
          </Text>
        </Box>
      </Text>
      <Text
        color="charcoal"
        as="button"
        textStyle="h6"
        onClick={() => router.push('/how-it-works')}
        mb={8}
      >
        How it Works
      </Text>
      <Text
        color="charcoal"
        as="button"
        textStyle="h6"
        onClick={() => router.push('/about-us')}
        mb={8}
      >
        About Us
      </Text>
      <Text
        color="charcoal"
        as="button"
        textStyle="h6"
        onClick={() => window.open(BLOG_URL, '_blank')}
        mb={8}
      >
        Blog
      </Text>
      <Text
        color="charcoal"
        as="button"
        textStyle="h6"
        onClick={() => router.push(Routes.GIFT_CARDS)}
        mb={8}
      >
        Gift Cards
      </Text>
    </>
  );
};

export const NavBar: React.FC<NavProps> = ({
  isCheckout,
  username,
  onClick,
  hideNavBarActions = false,
  variant = 'light',
  position,
}: NavProps) => {
  const {navRef} = useScrollContext();

  let actions: JSX.Element;
  if (isCheckout) {
    actions = <CheckoutActions />;
  } else if (username) {
    actions = <LoggedInActions username={username} variant={variant} />;
  } else {
    actions = <LoggedOutActions onClick={onClick} variant={variant} />;
  }

  const router = useRouter();
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Box
        position="relative"
        height={{base: NAV_HEIGHT_SM, md: NAV_HEIGHT_LG}}
      />
      <Box
        top="0"
        position="fixed"
        w="100%"
        zIndex="banner"
        sx={{
          ...(position === 'down'
            ? {
                transform: 'translateY(-100%)',
                transition: 'all 0.3s ease-in-out',
              }
            : {
                transform: 'translateY(0)',
                transition: 'all 0.3s ease-in-out',
              }),
        }}
      >
        <Box
          ref={navRef}
          borderBottom={NavVariantProps[variant].borderBottom}
          bg={NavVariantProps[variant].bg}
          height={{base: NAV_HEIGHT_SM, md: NAV_HEIGHT_LG}}
          zIndex="banner"
          w="100%"
          as="nav"
        >
          <Container
            maxW="none"
            px={{base: 2, lg: '72px'}}
            alignItems="center"
            position="relative"
            flexDirection="row"
            display="flex"
            height="100%"
          >
            <Flex display={{base: 'flex', lg: 'none'}} flex={1}>
              <IconMenu2
                size="24px"
                color={NavVariantProps[variant].color}
                stroke="2"
                onClick={onOpen}
              />
            </Flex>
            <Link href="/" aria-label="Showpiece Home">
              <a>
                <Box
                  width={{base: '150px', md: '180px'}}
                  display="block"
                  padding={2}
                >
                  <Image
                    layout="responsive"
                    width="180px"
                    height="53px"
                    src={NavVariantProps[variant].logo}
                    alt="Showpiece logo"
                  />
                </Box>
              </a>
            </Link>

            <Flex
              display={{base: 'none', lg: 'flex'}}
              flex={1}
              textStyle="button"
              fontWeight="600"
            >
              {!hideNavBarActions && actions}

              {/* User profile icon on Mobile */}
            </Flex>
            <Flex
              display={{base: 'flex', lg: 'none'}}
              justifyContent="flex-end"
              flex={1}
              as="button"
              aria-label="My account/Log in"
            >
              {username && (
                <IconUser
                  size="24px"
                  color={variant === 'light' ? 'black' : 'white'}
                  stroke="2"
                  onClick={() => router.push('/my-account')}
                />
              )}
            </Flex>
          </Container>
          {/* Drawer for the mobile user actions */}
          <NavBarMobileMenu
            username={username}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        </Box>
      </Box>
    </>
  );
};
